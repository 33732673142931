import React from 'react'
import Figure from './Figure'
import SyntaxHighlighter from 'react-syntax-highlighter';
import { colorBrewer } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import getYouTubeId from 'get-youtube-id'
import YouTube from 'react-youtube'


const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    code: ({node = {}}) => {
      const { code, language } = node
      if (!code) {
        return null
      }
      return <SyntaxHighlighter 
              language={language || "text"} 
              style={colorBrewer} 
              lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}
              wrapLines={true}>
                {code}
              </SyntaxHighlighter>
    },
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    },
  }
}

export default serializers
